









import { ProfileData } from "@/views/ProfileSwitcher.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
	components:{}
})
export default class ProfileNavButton extends Vue {

	@Prop()
	public data:ProfileData;

	@Prop()
	public position:"left"|"right";

	@Prop()
	public lightMode:boolean;

	public over:boolean = false;
	public forceDefaultLogo:boolean = false;
	public get logo():string {
		if(this.forceDefaultLogo) {
			return "/logos/default.png";
		}else{
			return "/logos/"+this.data.id+".png";
		}
	}

	public get classes():string[] {
		let res = ["profilenavbutton"];
		res.push(this.position);
		if(this.over) res.push("open");
		if(this.lightMode) res.push("lightMode");
		return res;
	}

	public get title():string {
		if(this.data?.title) return this.data?.title;
		return "";
	}

	public get url():string {
		let route = this.$route.path;
		return "https://"+this.data.domains[0] + route;
	}

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

	/**
	 * Called if logo loading failed
	 */
	public onLogoError():void {
		console.warn("No logo specified for profile \""+this.data.id+"\" on folder \"public/logos\". Fallback to default logo. Add a \""+this.data.id+".png\" on \"public/logos\" folder to change it !");
		this.forceDefaultLogo = true;
	}

}
