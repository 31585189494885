














import { Component, Vue, Watch } from "vue-property-decorator";
import Footer from "./components/Footer.vue";
import MainLoader from "./components/MainLoader.vue";
import Tooltip from "./components/Tooltip.vue";
import Config from "./utils/Config";
import Utils from "./utils/Utils";
import Alert from "./views/AlertView.vue";
import Confirm from "./views/Confirm.vue";
import ProfileSwitcher, { ProfileData } from "./views/ProfileSwitcher.vue";

@Component({
	components:{
		Alert,
		Footer,
		Tooltip,
		Confirm,
		MainLoader,
		ProfileSwitcher,
	}
})
export default class App extends Vue {

	public pageTitle:string = null;

	public get storeInitComplete():boolean {
		return this.$store.state.initComplete;
	}

	public get lightMode():boolean {
		return Utils.getRouteMetaValue(this.$route, "lightMode") === true;
	}

	public async mounted():Promise<void> {
		if(this.$store.state.profile) {
			this.onReady();
		}
	}

	public beforeDestroy():void {
	}

	@Watch("$store.state.initComplete", { immediate: true, deep: true })
	public onReady():void {
		let p = <ProfileData>this.$store.state.profile;
		if(!p) {
			this.pageTitle = Config.DEFAULT_PAGE_TITLE;
		}else{
			let title = p.title? p.title : Config.DEFAULT_PAGE_TITLE;
			this.pageTitle = title;
		}
	}

}
