



























import Button from "@/components/Button.vue";
import Config from "@/utils/Config";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
	components:{
		Button,
	}
})
export default class AuthForm extends Vue {
	@Prop()
	public lightMode:boolean;

	public token:string = null;
	public loading:boolean = false;
	public showForm:boolean = false;
	public showButton:boolean = true;

	public get oAuthURL():string {
		let path = this.$router.resolve({name:"oauth"}).href;
		let redirect = encodeURIComponent( document.location.origin+path );
		let scopes = encodeURIComponent( Config.TWITCH_SCOPES.join(" ") );
		let clientID = this.$store.state.clientID;

		let url = "https://id.twitch.tv/oauth2/authorize?";
		url += "client_id="+clientID
		url += "&redirect_uri="+redirect;
		url += "&response_type=token";
		url += "&scope="+scopes;
		url += "&state="+this.$route.name;//Used to redirect to the route we came from
		return url;
	}

	public async mounted():Promise<void> {
		
	}

	public beforeDestroy():void {
		
	}

	public onClose():void {
		this.showButton = true;
	}

	// @Watch("showForm")
	// public onFormChange():void {
	// 	gsap.set(this.$el, {scale:1, maxHeight:"250px"});
	// 	gsap.from(this.$el, {duration:.5, maxHeight:"35px", scale:1});
	// }

}
