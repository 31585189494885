






















import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Button from "./Button.vue";
import ShoutoutBot from "./obsconfig/ShoutoutBot.vue";
import ViewersRating from "./obsconfig/ViewersRating.vue";

@Component({
	components:{
		Button,
		ViewersRating,
		ShoutoutBot,
	}
})
export default class ObsConfigPanel extends Vue {

	public content:string = "shoutout";

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

	public close():void {
		this.$emit("close");
	}

}
