











































import { Component, Vue, Watch } from "vue-property-decorator";
import Button from "../Button.vue";

@Component({
	components:{
		Button,
	}
})
export default class ShoutoutBot extends Vue {

	public enabled:boolean = false;
	public botDescriptionFallback:boolean = false;
	public command:string = "";
	public text:string = "";
	public botRoleModerators:boolean = false;
	public botRoleVIP:boolean = false;
	public botRoleViewers:boolean = false;

	public get formClasses():string[] {
		let res = ["form"]
		if(!this.enabled) res.push("disabled");
		return res;
	}

	public mounted():void {
		if(this.$store.state.botShoutoutEnabled) {
			this.enabled = true;
		}

		//Prefill form from store
		this.command = this.$store.state.botCommand;
		this.text = this.$store.state.botText;
		this.botDescriptionFallback = this.$store.state.botDescriptionFallback;
		let roles = this.$store.state.botRoles;
		this.botRoleModerators = roles.includes("moderator");
		this.botRoleVIP = roles.includes("vip");
		this.botRoleViewers = roles.includes("viewer");
	}

	public beforeDestroy():void {
		
	}

	@Watch("enabled")
	public onToggle():void {
		this.$store.dispatch("setBotShoutoutEnabled", this.enabled);
	}

	@Watch("command")
	public onComandChange():void {
		this.$store.dispatch("setBotCommand", this.command);
	}

	@Watch("text")
	public onTextChange():void {
		this.$store.dispatch("setBotText", this.text);
	}

	@Watch("botDescriptionFallback")
	public onBotDescriptionFallbackChange():void {
		this.$store.dispatch("setBotDescriptionFallback", this.botDescriptionFallback);
	}

	@Watch("botRoleModerators")
	public onBotRoleModeratorsChange():void { this.updateRolesList(); }
	@Watch("botRoleVIP")
	public onBotRoleVIPChange():void { this.updateRolesList(); }
	@Watch("botRoleViewers")
	public onBotRoleViewersChange():void { this.updateRolesList(); }

	public reset():void {
		this.$store.dispatch("resetBotConfig");
		this.command = this.$store.state.botCommand;
		this.text = this.$store.state.botText;
		this.botDescriptionFallback = this.$store.state.botDescriptionFallback;
		let roles = this.$store.state.botRoles;
		this.botRoleModerators = roles.includes("moderator");
		this.botRoleVIP = roles.includes("vip");
		this.botRoleViewers = roles.includes("viewer");
	}

	/**
	 * Called anytime a role is checked or unchecked
	 */
	private updateRolesList():void {
		let roles = [];
		if(this.botRoleModerators) roles.push("moderator");
		if(this.botRoleVIP) roles.push("vip");
		if(this.botRoleViewers) roles.push("viewer");
		this.$store.dispatch("setBotRoles", roles);
	}

}
