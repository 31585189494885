




































































































































import AuthForm from "@/components/AuthForm.vue";
import ObsConfigPanel from "@/components/ObsConfigPanel.vue";
import Button from "@/components/Button.vue";
import MainLoader from "@/components/MainLoader.vue";
import OBSPanelInfo from "@/components/OBSPanelInfo.vue";
import StreamerForm from "@/components/StreamerForm.vue";
import StreamInfo from "@/components/StreamInfo.vue";
import Api, { ApiError } from "@/utils/Api";
import IRCClient from "@/utils/IRCClient";
import { TwitchTypes } from "@/utils/TwitchUtils";
import Utils from "@/utils/Utils";
import gsap from "gsap/all";
import { Component, Vue } from "vue-property-decorator";
import Config from "@/utils/Config";
import { ProfileData } from "./ProfileSwitcher.vue";

@Component({
	components: {
		Button,
		AuthForm,
		StreamInfo,
		MainLoader,
		StreamerForm,
		OBSPanelInfo,
		ObsConfigPanel,
	},
})
export default class Home extends Vue {

	public loading:boolean = true;
	public reloading:boolean = false;
	public loadError:boolean = false;
	public showOBSPanel:boolean = false;
	public showInactive:boolean = false;
	public showConfigPanel:boolean = false;
	public showProfileForm:boolean = false;
	public missingTwitchKeys:boolean = false;
	public invalidTwitchKeys:boolean = false;
	public missingTwitchUsers:boolean = false;
	public forceDefaultLogo:boolean = false;

	public onlineUsers:TwitchTypes.UserInfo[] = [];
	public offlineUsers:TwitchTypes.UserInfo[] = [];
	public inactiveUsers:TwitchTypes.UserInfo[] = [];
	
	private refreshTimeout:number;

	public get classes():string[] {
		let res = ["home"];
		if(this.lightMode) res.push("light");
		return res;
	}

	public get inactiveDays():number {
		return Config.INACTIVITY_DURATION / (1000 * 60 * 60 * 24);
	}

	public get lightMode():boolean {
		return Utils.getRouteMetaValue(this.$route, "lightMode") === true;
	}

	public get botShoutoutEnabled():boolean {
		return this.$store.state.botShoutoutEnabled;
	}

	public get connected():boolean {
		return this.$store.state.OAuthToken;
	}

	public get profile():ProfileData {
		return this.$store.state.profile;
	}

	public get title():string {
		if(this.profile?.title) return this.profile?.title;
		return Config.DEFAULT_PAGE_TITLE;
	}

	public get userFile():string {
		let res = "userList";
		if(this.profile.id) res += "_"+this.profile.id;
		return res;
	}

	public get logoPath():string {
		if(this.profile.id && !this.forceDefaultLogo) {
			return "/logos/"+this.profile.id+".png";
		}else{
			return "/logos/default.png";
		}
	}

	public get isAStreamer():boolean {
		let authLogin = IRCClient.instance.authenticatedUserLogin?.toLowerCase();

		return this.onlineUsers.findIndex(v => v.display_name.toLowerCase() == authLogin) > -1
		|| this.offlineUsers.findIndex(v => v.display_name.toLowerCase() == authLogin) > -1;
	}

	public async mounted():Promise<void> {
		this.loadData(true);
	}

	public beforeDestroy(): void {
		clearTimeout(this.refreshTimeout);
	}

	public randomRaid():void {
		let user:TwitchTypes.UserInfo;
		do {
			user = Utils.pickRand(this.onlineUsers);
		}while(user.display_name.toLowerCase() == this.$store.state.userLogin.toLowerCase());
		
		Utils.confirm("Lancer un raid", "Veux-tu vraiment lancer un raid vers la chaîne de "+user.display_name+" ?")
		.then(_=> {
			IRCClient.instance.sendMessage("/raid "+user.display_name);
		}).catch(error=>{});
	}

	public beforeEnter(el:HTMLElement):void {
		gsap.set(el, {opacity:0, y:-50});
	}

	public enter(el:HTMLDivElement):void {
		let delay = parseInt(el.dataset.index) * .15;
		gsap.to(el, {duration:.5, opacity:1, y:0, delay});
	}

	public leave(el:HTMLElement):void {

	}

	/**
	 * Called if logo loading failed
	 */
	public onLogoError():void {
		console.warn("No logo specified for current profile ("+this.$store.state.profile.id+") on folder \"public/logos\". Fallback to default logo. Add a \""+this.$store.state.profile.id+".png\" on \"public\" folder to change it !");
		this.forceDefaultLogo = true;
	}


	/**
	 * Loads all the data from server
	 */
	private async loadData(isFirstLoad:boolean = false):Promise<void> {
		if(isFirstLoad)  {
			//Avoids showing the loader when doing background reload
			this.loading = true;
		}
		
		let onlineUsers:TwitchTypes.UserInfo[] = [];
		let offlineUsers:TwitchTypes.UserInfo[] = [];
		let inactiveUsers:TwitchTypes.UserInfo[] = [];
		this.loadError = false;
		try {
			//Get channels list and states
			let result = await Api.get("private/stream_infos");
			if(result?.length > 0) {
				for (let i = 0; i < result.length; i++) {
					const user:TwitchTypes.UserInfo = result[i];
					if(user.streamInfos) {
						onlineUsers.push(user);
					}else{
						if(Date.now() - user.rawData.lastActivity > Config.INACTIVITY_DURATION) {
							inactiveUsers.push(user);
						}else{
							offlineUsers.push(user);
						}
					}
				}
			}else{
				this.missingTwitchUsers = true;
			}
		}catch(error) {
			this.loadError = true;
			this.loading = false;
			if(error instanceof ApiError) {
				if(error.error_code === "INVALID_TWITCH_KEYS") {
					this.invalidTwitchKeys = true;
					this.loadError = false;
				}else if(error.error_code === "INVALID_TWITCH_KEYS") {
					this.missingTwitchKeys = true;
					this.loadError = false;
				}
			}
			this.scheduleReload();
			return;
		}

		const today = new Date();
		if(this.profile.id == "protopotes" && today.getDate() === 1 && today.getMonth() == 3) {
			//April fool
			onlineUsers.push({
				id:                "135468063",
				login:             "antoinedaniel",
				display_name:      "AntoineDaniel",
				type:              "",
				broadcaster_type:  "partner",
				description:       "Et oui je suis là",
				profile_image_url: "https://static-cdn.jtvnw.net/jtv_user_pictures/7474d9d8-ab27-46c8-95f4-0c71b1878c78-profile_image-70x70.png",
				offline_image_url: "",
				view_count:        6000 + Math.round(Math.random() * 10000),
				created_at:        "2012-08-24T20:05:04Z",
				streamInfos:      {
					"id": "45113231804",
					"user_id": "135468063",
					"user_login": "antoinedaniel",
					"user_name": "AntoineDaniel",
					"game_id": "1469308723",
					"game_name": "Software and Game Development",
					"type": "live",
					"title": "Je viens d'arriver chez les Protopotes, je leur code un bot avec mes gros talents de codeur !",
					"viewer_count": 6000 + Math.round(Math.random() * 10000),
					"started_at": new Date(Date.now() - 5215000).toISOString(),
					"language": "fr",
					"thumbnail_url": "https://static-cdn.jtvnw.net/cf_vods/dgeft87wbj63p/35747a068688f7e3580b_antoinedaniel_45099654428_1648660897/thumb/thumb0-{width}x{height}.jpg",
					"tag_ids": [
						"6f655045-9989-4ef7-8f85-1edcec42d648"
					]
				},
				rawData:       {
					"name": "antoinedaniel",
					"created_at": Date.now(),
					"id": "45113231804",
					"lastActivity": Date.now()
				}
			})
		}

		onlineUsers.sort((a, b) => {
			if(a.streamInfos?.viewer_count > b.streamInfos?.viewer_count) return 1;
			if(a.streamInfos?.viewer_count < b.streamInfos?.viewer_count) return -1;
			return 0;
		})

		offlineUsers.sort((a, b) => {
			if(a.login.toLowerCase() > b.login.toLowerCase()) return 1;
			if(a.login.toLowerCase() < b.login.toLowerCase()) return -1;
			return 0;
		})

		inactiveUsers.sort((a, b) => {
			if(a.login.toLowerCase() > b.login.toLowerCase()) return 1;
			if(a.login.toLowerCase() < b.login.toLowerCase()) return -1;
			return 0;
		})

		this.loading = false;
		await this.$nextTick();
		this.onlineUsers = onlineUsers;
		this.offlineUsers = offlineUsers;
		this.inactiveUsers = inactiveUsers;
		
		this.scheduleReload();
	}

	/**
	 * Schedule a data reload in 2min
	 * Clears the previous schedule just in case.
	 */
	private scheduleReload():void {
		clearTimeout(this.refreshTimeout);
		this.refreshTimeout = setTimeout(_=> {
			this.loadData();
		}, 2 * 60 * 1000);
	}

	public logout():void {
		Utils.confirm("Deconnexion", "Souhaites-tu te déconnecter ?").then(()=>{
			this.$store.dispatch("logout");
		}).catch(()=>{});
	}

	public getOBSPanel():void {
		this.showOBSPanel = true;
	}

	public openConfigPanel():void {
		this.showConfigPanel = !this.showConfigPanel;
	}

	public reloadPage():void {
		this.reloading = true;
		document.location.reload();
	}

	public async displayInactiveUsers():Promise<void> {
		this.showInactive = true;
		await this.$nextTick();
		window.scrollBy(0, 100);
	}

}
