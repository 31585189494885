












import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Button from "../Button.vue";

@Component({
	components:{
		Button,
	}
})
export default class ViewersRating extends Vue {

	public enabled:boolean = false;

	public get formClasses():string[] {
		let res = ["form"]
		if(!this.enabled) res.push("disabled");
		return res;
	}

	public mounted():void {
		if(this.$store.state.botToxicEnabled) {
			this.enabled = true;
		}
		
	}

	public beforeDestroy():void {
		
	}

	@Watch("enabled")
	public onToggle():void {
		this.$store.dispatch("setBotToxicEnabled", this.enabled);
	}

}
