



























































import Config from "@/utils/Config";
import IRCClient from "@/utils/IRCClient";
import { TwitchTypes } from "@/utils/TwitchUtils";
import Utils from "@/utils/Utils";
import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "./Button.vue";

@Component({
	components:{
		Button,
	}
})
export default class StreamInfo extends Vue {

	@Prop()
	public userName:string;

	@Prop({default:false})
	public small!:boolean;

	@Prop()
	public userInfos:TwitchTypes.UserInfo;

	@Prop()
	public lightMode:boolean;
	
	public showLive:boolean = false;

	private increment:number = 0;
	private refreshThumbInterval:number;
	private incrementInterval:number;
	private pictureRefreshInc:number = 0;

	public get connected():boolean { return this.$store.state.OAuthToken; }

	public get streamInfos():TwitchTypes.StreamInfo { return this.userInfos.streamInfos; }
	
	public get isNewUser():boolean { return Date.now() - this.userInfos?.rawData.created_at < Config.NEW_USER_DURATION; }

	public get twitchParent():string {
		return document.location.hostname;
	}

	public get isSelf():boolean {
		return this.userInfos.login.toLowerCase() == this.$store.state.userLogin.toLowerCase();
	}

	public get userPicture():string {
		return this.userInfos.profile_image_url.replace("300x300", "70x70");
	}

	public get classes():string[] {
		let res = ["streaminfo"];
		if(this.showLive) res.push("expand");
		if(this.small !== false) res.push("small");
		if(this.lightMode !== false) res.push("light");
		if(this.isNewUser !== false) res.push("isNew");
		return res;
	}

	public get canRaid():boolean {
		return this.$store.state.OAuthToken;
	}

	public get streamDuration():string {
		let ellapsed = Date.now() - new Date(this.userInfos.streamInfos.started_at).getTime() + this.increment;
		return Utils.formatDuration(ellapsed)
	}

	public get previewUrl():string {
		return this.userInfos.streamInfos.thumbnail_url.replace(/\{width\}/gi, "340").replace(/\{height\}/gi, "190")+"?ck="+this.pictureRefreshInc;
	}

	public async mounted():Promise<void> {
		//Allows to increment stream durations every seconds without using requestAnimationFrame
		this.incrementInterval = setInterval(_=> {
			this.increment ++;
		},1000);

		//Refresh stream pictures every 5min + random duration to
		//avoid having all pictures refreshing at once
		this.refreshThumbInterval = setInterval(_=> {
			this.pictureRefreshInc ++;
		}, 5 * 60 * 1000 + Math.random()*30000);
	}

	public beforeDestroy():void {
		clearInterval(this.incrementInterval);
		clearInterval(this.refreshThumbInterval);
	}

	public hoverItem():void {
		this.showLive = true;
	}

	public outItem():void {
		this.showLive = false;
	}

	public startRaid():void {
		Utils.confirm("Lancer un raid", "Veux-tu vraiment lancer un raid vers la chaîne de "+this.userName+" ?")
		.then(_=> {
			IRCClient.instance.sendMessage("/raid "+this.userName);
		}).catch(error=>{});
	}

}
