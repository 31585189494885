































import Api from "@/utils/Api";
import Config from "@/utils/Config";
import IRCClient from "@/utils/IRCClient";
import gsap from "gsap/all";
import { Component, Vue } from "vue-property-decorator";
import Button from "./Button.vue";

@Component({
	components:{
		Button,
	}
})
export default class StreamerForm extends Vue {

	public showAPI:boolean = false;
	public description:string = "";
	public maxLengthDescription:number = 350;
	public saving:boolean = false;

	private keyUpHandler:any;

	public get userName():string{ return IRCClient.instance.authenticatedUserLogin; }

	public get apiUrl():string {
		return Config.API_PATH_ABSOLUTE+"/description?login="+this.userName;
	}

	public mounted():void {
		gsap.killTweensOf([this.$refs.holder, this.$refs.dimmer]);
		gsap.set(this.$refs.holder, {marginTop:0, opacity:1});
		gsap.to(this.$refs.dimmer, {duration:.25, opacity:1});
		gsap.from(this.$refs.holder, {duration:.25, marginTop:100, opacity:0, ease:"back.out"});

		this.loadDescription();

		this.keyUpHandler = (e:KeyboardEvent) => { if(e.key == "Escape") this.close(); }

		document.addEventListener("keyup", this.keyUpHandler);
	}

	public beforeDestroy():void {
		document.removeEventListener("keyup", this.keyUpHandler);
	}

	private async loadDescription():Promise<void> {
		this.description = await Api.get("description", {login:IRCClient.instance.authenticatedUserLogin});
	}

	public async submit():Promise<void> {
		this.saving = true;
		let res = await Api.post("description", {description:this.description, access_token:this.$store.state.OAuthToken});
		this.saving = false;
		this.close();
	}

	public close():void {
		gsap.killTweensOf([this.$refs.holder, this.$refs.dimmer]);
		gsap.to(this.$refs.dimmer, {duration:.25, opacity:0, ease:"sine.in"});
		gsap.to(this.$refs.holder, {duration:.25, marginTop:100, opacity:0, ease:"back.out", onComplete:()=> {
			this.$emit("close")
		}});
	}

}
