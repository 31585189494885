

























import Utils from "@/utils/Utils";
import gsap from "gsap/all";
import { Component, Vue } from "vue-property-decorator";
import Button from "./Button.vue";

@Component({
	components:{
		Button,
	}
})
export default class OBSPanelInfo extends Vue {

	public copiedText:boolean = false;

	private keyUpHandler:any;

	public get obsPanelURL():string {
		let route = this.$router.resolve({name: "obs"}).href;
		return document.location.origin + route;
	}

	public mounted():void {
		gsap.killTweensOf([this.$refs.holder, this.$refs.dimmer]);
		gsap.set(this.$refs.holder, {marginTop:0, opacity:1});
		gsap.to(this.$refs.dimmer, {duration:.25, opacity:1});
		gsap.from(this.$refs.holder, {duration:.25, marginTop:100, opacity:0, ease:"back.out"});

		this.keyUpHandler = (e:KeyboardEvent) => { if(e.key == "Escape") this.close(); }

		document.addEventListener("keyup", this.keyUpHandler);
	}

	public beforeDestroy():void {
		document.removeEventListener("keyup", this.keyUpHandler);
	}

	public close():void {
		gsap.killTweensOf([this.$refs.holder, this.$refs.dimmer]);
		gsap.to(this.$refs.dimmer, {duration:.25, opacity:0, ease:"sine.in"});
		gsap.to(this.$refs.holder, {duration:.25, marginTop:100, opacity:0, ease:"back.out", onComplete:()=> {
			this.$emit("close");
		}});
	}

	public copyLink():void {
		Utils.copyToClipboard(this.obsPanelURL);
		gsap.set(this.$refs.url, {filter:"brightness(1) saturate(1)", background:"rgba(255,255,255,0)"});
		gsap.from(this.$refs.url, {duration:.5, ease:"sine.in", filter:"brightness(2) saturate(0)", background:"rgba(255,255,255,1)"});
		this.copiedText = true;
		setTimeout(()=> {
			this.copiedText = false;
		}, 1000);
	}

	public selectText():void {
        var range = document.createRange();
        range.selectNode(<HTMLParagraphElement>this.$refs.actualURL);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
	}

}
